import React, { useEffect, useState } from 'react';
import './ProductTable.css';
import Header from '../Header';
import Footer from '../Footer';
import { getMedia, updateMedia } from './apiService'; // Import your service

const MediaDisplay = () => {
  const [mediaData, setMediaData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newMediaUrl, setNewMediaUrl] = useState(''); // State for new media URL
  const [updated, setUpdated] = useState(false); // Flag to track if the media is updated


  useEffect(() => {
    // Fetch the media data from getMedia() service
    const fetchMedia = async () => {
      try {
        const data = await getMedia();
        console.log('Fetched Media Data:', data);  // Log the response data for debugging
        setMediaData(data.data);  // Set the media data to state
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchMedia();
    setNewMediaUrl('');
  }, [updated]);

  // Function to handle the PATCH request
  const handleUpdateMedia = async () => {
    if (!newMediaUrl.trim()) {
      alert("Please provide a media URL.");
      return;
    }

    try {
        const response = await updateMedia(newMediaUrl); // Call the updateMedia function
        if (response.status === 200) {
          setUpdated(true); // Mark as updated
          alert('Media URL updated successfully!');
          setMediaData((prevData) => ({ ...prevData, url: newMediaUrl })); // Update the media URL in the state
        }
      } catch (err) {
        alert('Failed to update media URL');
        console.error(err);
      }
    };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // Check if mediaData contains the img or url to display
  const images = mediaData && mediaData.img;
  const videoUrl = mediaData && mediaData.url;

  const isVideoOrImage = (url) => {
    if (url.endsWith(".mp4") || url.includes("video")) {
      return "video"; // It's a video
    } else {
      return "image"; // It's an image
    }
    // return null; // Unknown media type
  };

  // Check if mediaData contains the img or url to display
 
  return (
    <>
    <Header />
    <div className = "w-full max-w-4xl mx-auto overflow-auto max-h-[80vh] overflow-y-auto max-w-[100vh] overflow-x-auto">
    <div className="flex justify-center items-center p-4 bg-gray-200 rounded-lg shadow-lg ">
      <div className="max-h-[80vh]">
        {/* Display media form */}
        <div className="mb-4">
          <input
            type="text"
            value={newMediaUrl}
            onChange={(e) => setNewMediaUrl(e.target.value)}
            placeholder="Enter new media URL"
            className="custom-input"
          />
          <button
            onClick={handleUpdateMedia}
            // className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
          >
            Update Media
          </button>
        </div>

        {/* Check if images exist */}
        {images && images.length > 0 ? (
          images.map((url, index) => {
            const mediaType = isVideoOrImage(url);

            if (mediaType === "image") {
              return <img key={index} src={url} alt={`Media ${index}`} className="max-w-full max-h-[70vh] object-contain mb-4" />;
            } else if (mediaType === "video") {
              return <video key={index} controls autoPlay loop src={url} className="max-w-full max-h-[70vh] object-contain mb-4" />;
            } else {
              return <p key={index}>Unknown media type</p>;
            }
          })
        ) : videoUrl ? (
          <video controls autoPlay loop src={videoUrl} className="max-w-full max-h-[70vh] object-contain mb-4" />
        ) : (
          <p>No media available</p>
        )}
      </div>
    </div>
    </div >
    <Footer />
    </>
  );
};

export default MediaDisplay;