import React from 'react';
import ProductTable from './ProductTable';
import AddProductForm from './AddProductForm';
import OrderForm from './OrderForm';
import { getProducts, addProduct, updateProduct } from './apiService'; 
import Header from '../Header';
import Footer from '../Footer';
import './AdminLandingPage.css'; // Create this CSS for styling

const AdminLandingPage = () => {
  return (
    <div className="admin-landing-page">
      <Header />
      <main className="content">
        <ProductTable />
      </main>
      <Footer />
    </div>
  );
};

export default AdminLandingPage;
