import axios from 'axios';

//const API_BASE_URL = 'https://retoolapi.dev/3fY18F'; // Replace with your API URL
//const API_BASE_URL = 'https://retoolapi.dev/gMOGEs'; // Replace with your API URL
const API_BASE_URL = 'https://retoolapi.dev/3fY18F'; // Replace with your API URL
const API_BASE_URL2 = 'https://retoolapi.dev/X9XR9v/';
const API_MEDIA_URL = 'https://retoolapi.dev/CKmgwV'; // Replace with your API URL

export const getProducts = () => {
  //return axios.get(`${API_BASE_URL}/productdata`);
  //return axios.get(`${API_BASE_URL}/items`);
  return Promise.all([fetch(`${API_BASE_URL}/productdata`), fetch(`${API_BASE_URL2}/productdata2`)])
        .then(([response1, response2]) => {
          // Convert both responses to JSON
          return Promise.all([response1.json(), response2.json()]);
        })
        .then(([data1, data2]) => {
          // Combine the results from both API responses
          const combinedData = [...data1, ...data2];         
          return combinedData
        })

};

export const addProduct = (productData) => {
  //return axios.post(`${API_BASE_URL}/productdata`, productData);
  return axios.post(`${API_BASE_URL2}/items`, productData);
  //return axios.post(`${API_BASE_URL}/items`, productData);
};

export const updateProduct = (productId, updatedData) => {
  //return axios.patch(`${API_BASE_URL}/productdata/${productId}`, updatedData);
  //return axios.patch(`${API_BASE_URL}/items/${productId}`, updatedData);
  return Promise.all([fetch(`${API_BASE_URL}/productdata?code=${productId}`), fetch(`${API_BASE_URL2}/productdata2?code=${productId}`)])
  .then(([response1, response2]) => {
    return Promise.all([response1.json(), response2.json()]);
    }).then(([data1, data2]) => {
      // Check if response1 or response2 has data
      if (!data1 || data1.length === 0) {
        console.log('Data received from API_BASE_URL2:');
        let id = data2[0].id
        return axios.patch(`${API_BASE_URL2}/productdata2/${id}`, updatedData);
      } else {
        console.log('Data received from API_BASE_URL:',data1);
        let id = data1[0].id
        return axios.patch(`${API_BASE_URL}/productdata/${id}`, updatedData);
      }
    })
};
export const deleteProduct = (productId) => {
  //return axios.patch(`${API_BASE_URL}/productdata/${productId}`, updatedData);
  //return axios.patch(`${API_BASE_URL}/items/${productId}`, updatedData);
  return Promise.all([fetch(`${API_BASE_URL}/productdata?code=${productId}`), fetch(`${API_BASE_URL2}/productdata2?code=${productId}`)])
  .then(([response1, response2]) => {
    return Promise.all([response1.json(), response2.json()]);
    }).then(([data1, data2]) => {
      // Check if response1 or response2 has data
      if (!data1 || data1.length === 0) {
        console.log('Data received from API_BASE_URL2:');
        let id = data2[0].id
        return axios.delete(`${API_BASE_URL2}/productdata2/${id}`);
      } else {
        console.log('Data received from API_BASE_URL:',data1);
        let id = data1[0].id
        return axios.delete(`${API_BASE_URL}/productdata/${id}`);
      }
    })
};

export const getMedia = () => {
  return axios.get(`${API_MEDIA_URL}/Banner/2`);
  //return axios.get(`${API_BASE_URL}/items`);
};

export const updateMedia = (updatedData) => {
  return axios.patch(`${API_MEDIA_URL}/Banner/2`,{ img: [updatedData] });
  //return axios.patch(`${API_BASE_URL}/items/${productId}`, updatedData);
};