import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OrderForm.css';
import Header from '../Header';
import Footer from '../Footer';

const OrderForm = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingOrderId, setEditingOrderId] = useState(null);
  const [editedShipmentID, setEditedShipmentID] = useState('');
  const [editedStatus, setEditedStatus] = useState('');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://retoolapi.dev/lOh1zY/orders');
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        setError('Failed to load orders');
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const handleEditClick = (order) => {
    // Enter edit mode with initial values for shipmentID and status
    setEditingOrderId(order.id);
    setEditedShipmentID(order.shipmentID || '');
    setEditedStatus(order.status || '');
  };

  const handleSaveClick = async (id) => {

    const currentOrder = orders.find((order) => order.id === id);
    // Update only shipmentID and status in the API
    try {
      await axios.put(`https://retoolapi.dev/lOh1zY/orders/${id}`, {
        ...currentOrder,
        shipmentID: editedShipmentID,
        status: editedStatus,
      });

      // Update the local order data after successful save
      setOrders(
        orders.map((order) =>
          order.id === id
            ? { ...order, shipmentID: editedShipmentID, status: editedStatus }
            : order
        )
      );

      // Exit edit mode
      setEditingOrderId(null);
    } catch (error) {
      console.error('Failed to update order', error);
    }
  };

  const handleCancelClick = () => {
    // Exit edit mode without saving
    setEditingOrderId(null);
  };

  if (loading) {
    return (
      <>
        <Header />
        <div style={{ paddingTop: '10px', paddingBottom: '10px', overflowY: 'auto', height: '87vh' }}>
          <div className="text-center">Loading...</div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <>
      <Header />
      <div style={{ paddingTop: '10px', paddingBottom: '10px', overflowY: 'auto', height: '87vh' }}>
        <h2 className="text-2xl font-semibold mb-4">Order Details</h2>
        <div className="overflow-auto h-[calc(100%-3.5rem)]">
          <table className="min-w-full">
            <thead>
              <tr>
                <th>ID</th>
                <th>Order ID</th>
                <th>Code</th>
                <th>Product ID</th>
                <th>Item Name</th>
                <th>Size</th>
                <th>Quantity</th>
                <th>Color</th>
                <th>Name</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Email</th>
                <th>Contact</th>
                <th>Payment Mode</th>
                <th>Amount</th>
                <th>Shipment ID</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{order.transactionID}</td>
                  <td>{order.itemID}</td>
                  <td>{order.prodID}</td>
                  <td>{order.itemName}</td>
                  <td>{order.itemSize}</td>
                  <td>{order.quantity}</td>
                  <td>{order.itemColor}</td>
                  <td>{order.personName}</td>
                  <td>{order.personAddress}</td>
                  <td>{order.city}</td>
                  <td>{order.state}</td>
                  <td>{order.contactEmail}</td>
                  <td>{order.contactNumber}</td>
                  <td>{order.modeOfPayment}</td>
                  <td>{"₹"+order.amount/100+".00"}</td>
                  <td>
                    {editingOrderId === order.id ? (
                      <input
                        type="text"
                        value={editedShipmentID}
                        onChange={(e) => setEditedShipmentID(e.target.value)}
                      />
                    ) : (
                      order.shipmentID
                    )}
                  </td>
                  <td>
                    {editingOrderId === order.id ? (
                      <input
                        type="text"
                        value={editedStatus}
                        onChange={(e) => setEditedStatus(e.target.value)}
                      />
                    ) : (
                      order.status
                    )}
                  </td>
                  <td>
                    {editingOrderId === order.id ? (
                      <>
                        <button onClick={() => handleSaveClick(order.id)} className="mr-2">
                          Save
                        </button>
                        <button onClick={handleCancelClick}>Cancel</button>
                      </>
                    ) : (
                      <button onClick={() => handleEditClick(order)}>Edit</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OrderForm;
