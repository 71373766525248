// App.js
import React from 'react';
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLogin from './component/AdminLogin';
import AdminLandingPage from './component/AdminLandingPage';
import AddProductForm from './component/AddProductForm';
import OrderForm from './component/OrderForm';
import ImageUploader from './component/BannerImg';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<AdminLogin />} />
        <Route path="/dashboard" element={<AdminLandingPage />} />
        <Route path="/addnew" element={<AddProductForm />} />
        <Route path="/Banner" element={<ImageUploader />} />
        <Route path="/Orders" element={<OrderForm />} />
      </Routes>
    </Router>
  );
}

export default App;
