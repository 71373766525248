import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // 
import { FaTachometerAlt, FaShoppingCart, FaImage, FaPlusCircle, FaSignOutAlt, FaBars } from 'react-icons/fa'; // Import FaBars icon

const Header = () => {
  return (
    <header className="header">
      <div className="container">
        <h1 className="logo">Admin Dashboard</h1> {/* Title for large screens */}
        <div className="mobile-logo"> {/* Icon for mobile view */}
          <FaBars size={30} />
        </div>
        <nav>
          <ul>
            <li>
              <Link to="/dashboard">
                <FaTachometerAlt size={20} /> 
                <h5 className="link">Dashboard</h5>
              </Link>
            </li>
            <li>
              <Link to="/orders">
                <FaShoppingCart size={20} /> 
                <h5 className="link">Orders</h5>
              </Link>
            </li>
            <li>
              <Link to="/Banner">
                <FaImage size={20} /> 
                <h5 className="link">Banner</h5>
              </Link>
            </li>
            <li>
              <Link to="/addnew">
                <FaPlusCircle size={20} /> 
                <h5 className="link">AddNew</h5>
              </Link>
            </li>
            <li>
              <Link to="/">
                <FaSignOutAlt size={20} /> 
                <h5 className="link">Logout</h5>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;