import React, { useState, useEffect } from 'react';
import './ProductTable.css';
import { getProducts, addProduct, updateProduct, deleteProduct, getMedia } from './apiService'; // Import your service

const ProductTable = () => {
    const [products, setProducts] = useState([]);
    const [media, setMedia] = useState([]);
    const [editingProductId, setEditingProductId] = useState(null);
    const [editableData, setEditableData] = useState([]);

    // Fetch products from the API on component mount
    useEffect(() => {
        fetchProducts();
        fetchMedia();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await getProducts();
            console.log(response);
            setProducts(response);
            setEditableData(response.reverse());
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const fetchMedia = async () => {
        try {
            const response = await getMedia();
            setMedia(response);
        } catch (error) {
            console.error('Error fetching media:', error);
        }
    };

    // Handle Edit button click
    const handleEdit = (id) => {
        setEditingProductId(id);
    };

    // Handle Save button click for PATCH
    const handleSave = async () => {
        const product = editableData.find((p) => p.code === editingProductId);
        try {
            await updateProduct(editingProductId, product); // PATCH request to update
            setEditingProductId(null);
            fetchProducts(); // Refresh data
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    // Handle Cancel button click
    const handleCancel = () => {
        setEditingProductId(null);
        setEditableData(products); // Reset to original data
    };

    // Handle input changes (for editing)
    const handleInputChange = (e, productId, fieldName) => {
        const updatedProducts = editableData.map((product) => {
            if (product.code === productId) {
                let value = e.target.value;
                if (fieldName === 'fabric' || fieldName === 'color' || fieldName === 'image' || fieldName === 'washcare') {
                    value = value.split(',').map((item) => item.trim());
                } else if (fieldName === 'details') {
                    value = value.split('\n').map(line => line.split(',').map(item => item.trim()));
                }
                return { ...product, [fieldName]: value };
            }
            return product;
        });
        setEditableData(updatedProducts);
    };

    const handleAddProduct = async (productData) => {
        try {
            await addProduct(productData); // POST request to add product
            fetchProducts(); // Refresh product list
        } catch (error) {
            console.error('Error adding product:', error);
        }
    };

    // Handle Delete button click
    const handleDelete = async (productId) => {
        try {
            await deleteProduct(productId); // DELETE request to remove product
            fetchProducts(); // Refresh product list
        } catch (error) {
            console.error('Error deleting product:', error);
        }
    };

    return (
        <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
            <h1 style={{ color: '#5f2570' }}>Product Management</h1>
            <div style={{ overflowY: 'auto', height: '70vh', border: '1px solid #e5e5e5' }}>
                <table border="1" style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Code</th>
                            <th>Type</th>
                            <th>ImageUrl</th>
                            <th>Details</th>
                            <th>Color</th>
                            <th>Fabric</th>
                            <th>Category</th>
                            <th>Washcare</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {editableData.map((product, index) => (
                            <tr key={product.code}>
                                <td>{index}</td>
                                <td>
                                    {editingProductId === product.code ? (
                                        <input
                                            type="text"
                                            value={product.code}
                                            onChange={(e) => handleInputChange(e, product.code, 'code')}
                                        />
                                    ) : (
                                        product.code
                                    )}
                                </td>
                                <td>
                                    {editingProductId === product.code ? (
                                        <input
                                            type="text"
                                            value={product.type}
                                            onChange={(e) => handleInputChange(e, product.code, 'type')}
                                        />
                                    ) : (
                                        product.type
                                    )}
                                </td>
                                <td>
                                    {editingProductId === product.code ? (
                                        <textarea
                                            style={{ width: "167px", height: "100px" }}
                                            value={product.image.join('\n')} // Display the image URLs joined by newlines
                                            onChange={(e) => handleInputChange(e, product.code, 'image')}
                                        />
                                    ) : (
                                        <div>
                                            {product.image.map((imgUrl, index) => (
                                                <img
                                                    key={index}
                                                    src={imgUrl}
                                                    alt={`product-${product.id}-${index}`}
                                                    width="50"
                                                    height="50"
                                                    style={{ display: 'block', marginBottom: '5px' }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                </td>

                                <td style={{ width: "167px", height: "121px" }}>
                                    {editingProductId === product.code ? (
                                        <textarea
                                            type="text"
                                            value={product.details.map(item => item.join(', ')).join('\n')} // Ensure details are displayed as joined text
                                            onChange={(e) => handleInputChange(e, product.code, 'details')}
                                            style={{ width: "167px", height: "121px" }}
                                        />
                                    ) : (
                                        product.details.join('\n') // Display details joined by newlines
                                    )}
                                </td>
                                <td>
                                    {editingProductId === product.code ? (
                                        <input
                                            type="text"
                                            value={product.color.join(', ')}
                                            onChange={(e) => handleInputChange(e, product.code, 'color')}
                                        />
                                    ) : (
                                        product.color.join(', ')
                                    )}
                                </td>
                                <td>
                                    {editingProductId === product.code ? (
                                        <input
                                            type="text"
                                            value={product.fabric.join(', ')}
                                            onChange={(e) => handleInputChange(e, product.code, 'fabric')}
                                        />
                                    ) : (
                                        product.fabric.join(', ')
                                    )}
                                </td>
                                <td>
                                    {editingProductId === product.code ? (
                                        <input
                                            type="text"
                                            value={product.category}
                                            onChange={(e) => handleInputChange(e, product.code, 'category')}
                                        />
                                    ) : (
                                        product.category
                                    )}

                                </td>
                                <td>
                                    {editingProductId === product.code ? (
                                        <input
                                            type="text"
                                            value={product.washcare.join(', ')}
                                            onChange={(e) => handleInputChange(e, product.code, 'washcare')}
                                        />
                                    ) : (
                                        product.washcare.join(', ')
                                    )}
                                </td>
                                <td>
                                    {editingProductId === product.code ? (
                                        <>
                                            <button onClick={handleSave}>Save</button>
                                            <button onClick={handleCancel}>Cancel</button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={() => handleEdit(product.code)}>Edit</button>
                                            <button onClick={() => handleDelete(product.code)}>Delete</button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductTable;
