// Footer.js
import React from 'react';
import './App.css'; // Create this CSS for styling
//import './Footer.css'; // Create this CSS for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <p>© {new Date().getFullYear()} EKAATTIRE. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
