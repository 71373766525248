import React, { useState } from 'react';
import 'normalize.css';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css'; 

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();
    setError(''); // Clear previous error

    // Basic validation
    if (!email || !password) {
      setError('Please fill in both fields.');
      return;
    }

    // Replace this with actual authentication logic
    if (email === 'admin@ekaattire.com' && password === 'hiranyam@123') {
      // Redirect to landing page
      navigate('/dashboard');
    } else {
      setError('Invalid email or password.');
    }
  };

  return (
    <div className="login-container">
      {/* Logo Section */}
      

      <form className="login-form" onSubmit={handleLogin}>
      <div className="logo-container">
        <img src="EKA_ATTIRE_LOGO.png" alt="Logo" className="logo" />
      </div>
        <h2>Admin Panel Login</h2>

        {error && <p className="error-message">{error}</p>}

        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="login-btn">Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;
